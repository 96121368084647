import axios from 'axios'
import router from '@/router'
// import { formatDate } from './index'
import { storage } from '@/storage'
import { Toast } from 'vant'

/**
 * 登录
 */
export function login (data) {
  return _request('/user_login', 'post', data, undefined, true)
}
/**
 * 手机验证码
 */
export function sendCode (params) {
  return _request('/send_code', 'get', undefined, params, true)
}
/**
 * 登出
 */
export function logout (data) {
  return _request('/auth/out', 'post', data, {})
}
/**
 * 首页banner
 */
export function banners () {
  return _request('/banner', 'get', undefined, {})
}
/**
 * 培训列表
 */
export function trains (data) {
  return _request('/trains', 'get', data, {})
}
/**
 * 培训详情 id
 */
export function trainsDetail (params) {
  return _request(`/trains_show/${params.id}`, 'get')
}
/**
 * 订单列表：is_paid //选传  是否支付 1支付0未支付
 */
export function orders (params) {
  return _request('/get_orders', 'get', undefined, params)
}
/**
 * 用户信息
 */
export function userInfo () {
  return _request('/get_user_info', 'get')
}
/**
 * 修改用户信息
 */
export function updateUserInfo (data) {
  return _request('/user_info_edit', 'post', {}, data)
}
/**
 * 下单 param train_id
 */
export function saveOrder (data) {
  return _request('/save_order', 'post', data, {})
}
/**
 * 取消订单 param train_id
 */
export function cancelOrder (param) {
  return _request(`/cancel_order/${param.id}`, 'get')
}
/**
 * 微信支付
 * @param {order_id} 订单id 
 * @returns 
 */
export function payOrder (data) {
  return _request('/go_pay', 'post', data)
}

export function _request(url, methods, data = undefined, params = {}, auth = false) {
  let loadingInstance
  loadingInstance = Toast.loading({
    duration: 0, // 持续展示 toast
    forbidClick: true,
    message: 'Loading...'
  })
  // console.log('data:', data)
  const configHeaders = { Token: storage.get('token') || '' }
  if (auth) {
    configHeaders.Authorization = ''
  }
  return new Promise((resolve, reject) => {
    axios({
      baseURL: '/api',
      method: methods,
      url: url,
      headers: configHeaders,
      params: params,
      data: data
    }).then((response) => {
      loadingInstance.close()
      // console.log('=====', response.data.code)
      const message = response.data.message || response.data.msg || ''
      const statusCode = response.data.code || response.data.status
      if (statusCode != '200') {
        if (message !== '') {
          Toast.fail(message)
        } else {
          Toast.fail('系统出错了，请重试！')
        }
        if (statusCode == 401) {
          storage.clear()
          router.push('/login').catch(err => err)
        }
      } else {
        // if (message !== '') {
        //   Message.success(message)
        // }
        return resolve(response.data)
      }
    }).catch((error) => {
      loadingInstance.close()
      // console.log('=====', error)
      Toast.fail('系统出错了，请重试！', error)
      return reject(error)
    })
  })
}

axios.interceptors.response.use(
  response => {
    // console.log('----interceptors:::response:', response)
    return response
  },
  error => {
    console.log('--interceptors--error', error.response)
    if (error.response.status == 401) {
      storage.clear()
      router.push('/login').catch(err => err)
    }
    return Promise.reject(error.response.data)
  }
)
