import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/protocol',
		name: 'Protocol',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/Protocol.vue')
	},
	{
		path:'/login',
		name:'Login',
		component:()=>import('../views/Login.vue')
	},
	{
		path:'/order',
		name:'Order',
		component:()=>import('../views/Orders.vue')
	},
	{
		path:'/info',
		name:'Info',
		component:()=>import('../views/Info.vue')
	},
	{
		path:'/detail',
		name:'Detail',
		component:()=>import('../views/Detail.vue')
	},
	{
		path:'/success',
		name:'Success',
		component:()=>import('../views/Success.vue')
	}
]

const router = new VueRouter({
	routes
})

export default router
