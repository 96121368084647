<template>
    <div id="app">
        <div class="head flex-between flex-ai-center">
            <span @click="$router.push('/')">
				<img src="./img/logo.jpg" alt="logo">
			</span>
            <span v-if="!isLogin" @click="_login">注册/登录</span>
			<van-popover
				v-if="isLogin"
				v-model="showPopover"
				trigger="click"
				:actions="actions"
				@select="onActionSelect"
				>
				<template #reference>
					<van-icon name="user-o" />
				</template>
			</van-popover>
        </div>

        <div class="hr"></div>
        <router-view @onlogin="isLogin = true"/>
    </div>
</template>
<script>
import { storage } from '@/storage'

export default {
    data() {
        return {
			active: 0,
			showPopover: false,
			isLogin:false,
			activeKey: 0,
			actions: [{ text: '我的订单', id: 0 }, { text: '个人信息', id: 1 }, { text: '退出登录', id: 2 }],
        }
	},
	methods: {
		onActionSelect (action) {
			if (action.id == 0) {
				this._replace('/order')
			} else if (action.id == 1) {
				this._replace('/info')
			} else {
				this._logout()
			}
		},
		_login () {
			this._replace('/login')
		},
		_replace(path) {
			if (this.$route.path !== path) {
				this.$router.push(path)
			}
		},
		_logout(){
			storage.clear()
			this._replace('/')
			this.isLogin = false
		}
	},
	mounted(){
		let loggedIn = storage.get('token')
		this.isLogin = loggedIn || this.$route.name === 'Login'
	}
};
</script>

<style lang="less" scoped>
.head {
    height: 0.8rem;
    font-size: 0.28rem;
    // line-height: 0.8rem;
	padding: .5rem .4rem;
	// position: fixed;
	// z-index: 9;
}
.head img{
	width: 2rem;
	height: .5rem;
}
.hr {
    background: #ccc;
    height: 1px;
    margin-bottom: 5px;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
    padding: 0;
    margin: 0;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
fieldset,
img {
    border: 0;
}
address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
    font-weight: normal;
    font-style: normal;
}
ol,
ul {
    list-style: none;
}
caption,
th {
    text-align: left;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
    font-size: 100%;
}
q:before,
q:after {
    content: ”;
}
abbr,
acronym {
    border: 0;
}
</style>
