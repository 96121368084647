<template>
    <div class="home">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
            <van-swipe-item v-for="(item, index) in bannerList" :key="index">
                <img v-lazy="item.pic" width="100%" @click="_gotoPage(item.url)" />
            </van-swipe-item>
        </van-swipe>
        <van-tabs v-model="active" title-active-color="white" title-inactive-color="#666666" line-width="0">
            <van-tab v-for="(name, idx) in catalogList" :key="idx" :title="name" :title-style="active == idx ? titleStyle : ''"></van-tab>
        </van-tabs>
        <van-grid clickable :border="false" square :column-num="2">
            <van-grid-item
                v-for="card in dataList"
                :key="card.id"
                :to="`detail?id=${card.id}`">
                <card :item="card"></card>
            </van-grid-item>
        </van-grid>
    </div>
</template>

<script>
// @ is an alias to /src
import { banners, trains } from '@/util/api'
import Card from '../components/Card.vue'

export default {
    name: "Home",
    components: {
        Card
    },
    data() {
        return {
            active: 0,
            bannerList: [],
            dataList: [],
            catalogList: ['全部'],
            titleStyle: {
                background: 'linear-gradient(90deg, #38B5F8 0%, #1A7EEF 100%)',
                'border-radius': '0px 13px 0px 13px',
                height: '37px',
                flex: 'none',
                padding: '20px'
            }
        };
    },
    created () {
        this._getBanners()
        this._getDataList()
    },
    methods: {
        _getBanners () {
            banners().then(res => {
                this.bannerList = res.data || []
            })
        },
        _gotoPage (url) {
            if (!url.includes('http')) {
                url = `http://${url}`
            }
            window.open(url)
        },
        _getDataList () {
            trains().then(res => {
                this.dataList = res.data || []
            })
        }
    }
};
</script>

<style scoped>
.swipe{
    width: 7.5rem !important;
}
.home {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 50px;
}
.my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    /* line-height: 150px; */
    text-align: center;
    margin-bottom: .3rem;
    /* background-color: #39a9ed; */
}
.title-class {
    background: linear-gradient(90deg, #38B5F8 0%, #1A7EEF 100%);
    border-radius: 0px 13px 0px 13px;
    margin: .2rem;
}
</style>
