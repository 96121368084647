<template>
  <div>
    <img :src="item.banner" height="105px" alt="" />
    <p class="card-title">{{ item.title }}</p>
    <div class="card-bottom">
      <span class="card-price">¥{{ item.get_charge.attr1_price }}</span>
      <span class="card-price-line">¥{{ item.get_charge.attr2_price }}</span>
      <span class="card-count">{{ item.sale_num }}人已学</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: {
    item: {
      type: Object,
      default: {},
    },
    context: {
      type: String,
      default: "list", // list shelf detail
    },
  },
  methods: {
    onCardClick() {
      // this.$router.push(`carddetail/${this.card.cardId || this.card.id}`)
      // console.log(this.$route)
      // if (this.$route.path.includes('carddetail')) {
      //   this.$router.push(`${this.card.cardId || this.card.id}`)
      // } else {
      //   this.$router.push(`carddetail/${this.card.cardId || this.card.id}`)
      // }
      let id = this.item.id;
      this.$router.push({ name: "carddetail", params: { id: id } });
    },
  },
};
</script>

<style scoped>
.card-title {
  font-size: 0.2rem;
}
.card-bottom {
  /* background: #39a9ed; */
  /* width: 3.8rem; */
  display: flex;
  justify-content: space-between;
}
.card-price {
  font-size: 0.25rem;
  color: red;
}
.card-price-line {
  font-size: 0.2rem;
  color: #cccccc;
  text-decoration-line: line-through;
}
.card-count {
  font-size: 0.2rem;
  color: #ccc;
}
</style>
